@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sonsie+One&display=swap');
@font-face {
  font-family: 'Lozung';
  src: url('./fonts/Lozung.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.sonsie {
  font-family: "Sonsie One", system-ui;
} 
.roboto {
  font-family: "Roboto", sans-serif;
}
.manrope{
  font-family: "Manrope", sans-serif;
}
.lozung{
  font-family: "Lozung", sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
  }
::-webkit-scrollbar-track {
    background: #1C1C25;
}
::-webkit-scrollbar-thumb {
    background: #25FFE5;;
    border-radius: 20px;
    
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (min-width: 1281px) {
    .left-blur {
        position: absolute;
        left: 0px;
        top: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(-90deg, rgba(0, 0, 0, 0) 0%, #1C1C25 100%);
        z-index: 99;
    }
    
    .right-blur {
        position: absolute;
        right: 0px;
        top: 0;
        width: 50px;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #1C1C25 100%);
        z-index: 99;
    }
}

.header{
    border-radius: 80px;
    background-color: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(15px);
}
.Dropdown-control {
    cursor: pointer;
    font-style: normal!important;
    font-size: 16px!important;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    background-color: transparent!important;
    border: none!important;
    color: rgba(255, 255, 255, 0.70)!important;
    text-transform: uppercase!important;
    padding-right:25px!important;
    
  }
  .Dropdown-menu {
    font-style: normal;
    font-size: 16px!important;
    max-height: 300px!important;
    font-family: "Roboto", sans-serif;
    
  }
  .Dropdown-arrow {
    border-color: rgba(255, 255, 255, 0.70) transparent transparent!important;
    margin-top: 3px;
  }
  .is-open .Dropdown-arrow {
    border-color: transparent transparent rgba(255, 255, 255, 0.70)!important;
  }

  .button{
    border-radius: 74px;
    border: 0.5px solid #918F90;
    background: linear-gradient(180deg, #1D1B1C 0%, #000 81.19%, #252120 96.35%);
    box-shadow: 0px 0px 0.25px 1.25px #262524 inset, 3px 5px 2px -4.75px #FFF inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1px 1px 3px 3px #1A1818 inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset;
    display: inline-flex;
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .bgline{
    background: linear-gradient(90deg, #25FFE5 0%, rgba(37, 255, 229, 0.00) 100%);
  }